import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { isMobile } from '../../utils/helpers';
import Section from '../Section';
import './index.scss';
import { useAssetsManifestContext } from '../contexts/AssetsManifestContext';

const VideoSection = () => {
	const assetsManifest = useAssetsManifestContext();
	const videos = assetsManifest['video-section'];
	const [activeSlide, setActiveSlide] = useState(0);
	const sliderRef = useRef();

	return (
		<Section id='videoSection' className='video-section'>
			<div className='width-bounding'>
				<Slider
					afterChange={current => {
						if (current === activeSlide) return;
						setActiveSlide(current);

						//stop all
						document.querySelectorAll('video').forEach(video => {
							video.pause();
							video.currentTime = 0;
						});
						// play current
						document.querySelector(`.slick-current video`).play();
					}}
					dots
					ref={sliderRef}
					centerMode
					slidesToShow={3}
					swipeToSlide
					lazyLoad
					focusOnSelect
					arrows={false}
					responsive={[
						{ breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1, centerMode: false } },
					]}>
					{videos.map((video, index) => (
						<div key={index} className='video-container'>
							<video
								src={video}
								controls={activeSlide === index}
								autoPlay={isMobile()}
								playsInline
								preload='metadata'
							/>
						</div>
					))}
				</Slider>
			</div>
		</Section>
	);
};

export default VideoSection;
