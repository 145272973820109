import React from 'react';
import Section from '../Section';
import './index.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { STORAGE_FOURTH_URL } from '../../scripts/gcpStorage.mjs';

const ThirdSecion = () => {
	return (
		<Section id='thirdSection' className='simple-section  third-section'>
			<div className='width-bounding'>
				<AnimationOnScroll animateIn='animate__fadeInUp' animateOnce animatePreScroll={false}>
					<div className='right-side'>
						<h2 className='header'>תלבושות</h2>
						<div className='text'>
							בין הדברים האהובים עלינו בבנות מלך זה המדידות. אירוע חינה הוא פעם בחיים, ולכן חשוב שתראו בו
							הכי מושלם שאפשר. אנחנו מציעים מגוון רחב של תלבושות, מבדים נוחים ואיכותיים, חלקן גם מייבוא
							אישי ממרוקו. חוששים שזה קטן? גדול? אין שום בעיה, בבנות מלך אנו מבינים שלכל אחת גזרה שונה,
							בדיוק בשביל זה יש לנו תופרת צמודה שיודעת לבצע תיקונים קטנים על מנת שהתלבושות תהיינה בול
							עליכן, נוחות ויפות. מעבר לתלבושות יש גם אקססוריז הכולל תכשיטים, אביזרי שיער, תרבושים מיוחדים
							ועוד, לחתן, לכלה וכמובן לאורחים. וכל זה כדי שתרגישו הכי מיוחדים שיש ביום הגדול שלכם!
						</div>
					</div>
				</AnimationOnScroll>
				<AnimationOnScroll animateIn='animate__fadeInUp' animateOnce animatePreScroll={false}>
					<div className='photo-container left-side'>
						<img
							src={`${STORAGE_FOURTH_URL}/outfit/700c8fad-d5c0-4664-954f-b0c5ea3e315e.JPG`}
							alt='outfit'
						/>
					</div>
				</AnimationOnScroll>
			</div>
		</Section>
	);
};

export default ThirdSecion;
