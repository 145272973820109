import React from 'react';
import { mergeClassNames } from '../../utils/classNames';
import './index.scss';

const Section = props => {
	const { className: propsClassName, children, ...passProps } = props;
	const className = mergeClassNames('section', propsClassName);
	return (
		<div {...passProps} className={className}>
			{children}
		</div>
	);
};

export default Section;
