import React, { useState, useMemo } from 'react';
import { ThemeProvider as ThemeProviderMUI, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ColorModeContext } from '../contexts/ThemeContext';

const ThemeProvider = ({ children }) => {
	const [mode, setMode] = useState('light');
	const colorMode = useMemo(
		() => ({
			mode,
			toggleColorMode: () => {
				setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
			},
		}),
		[mode],
	);

	const theme = useMemo(
		() =>
			createTheme(
				{
					palette: {
						mode,
						...(mode === 'light'
							? {
									//pallete values for dark mode
									primary: {
										main: '#d7bd68',
									},
									secondary: {
										main: '#08174d',
									},
									white: 'white',
							  }
							: {
									//pallete values for dark mode
							  }),
					},
					components: {
						MuiButton: {
							styleOverrides: {
								root: {
									textTransform: 'initial',
									height: 45,
									fontSize: 15,
								},
							},
						},
					},
				},
				{
					dimensions: {
						drawerWidth: 300,
						headerHeight: 80,
					},
				},
			),
		[mode],
	);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProviderMUI theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProviderMUI>
		</ColorModeContext.Provider>
	);
};

export default ThemeProvider;
