import fetch from 'node-fetch';
import convert from 'xml-js';

export const STORAGE_VIDEOS_URL = 'https://storage.googleapis.com/video-section';
export const STORAGE_FOURTH_URL = 'https://storage.googleapis.com/fourth-section';
export const getStorageMetaData = async () => {
	try {
		const [videosMetaData, fourthMetaData] = await Promise.all([
			fetch(STORAGE_VIDEOS_URL),
			fetch(STORAGE_FOURTH_URL),
		]);

		const videosXml = await videosMetaData.text();
		const fourthXml = await fourthMetaData.text();
		const videosContents = convert.xml2js(videosXml, { compact: true }).ListBucketResult.Contents;
		const fourthContents = convert.xml2js(fourthXml, { compact: true }).ListBucketResult.Contents;

		const assetsManifest = {
			'video-section': [],
			'fourth-section': {
				baskets: [],
				decoration: [],
				outfit: [],
			},
		};
		videosContents.forEach(content =>
			assetsManifest['video-section'].push(`${STORAGE_VIDEOS_URL}/${content.Key._text}`),
		);
		fourthContents.forEach(content => {
			const splittedKey = content.Key._text.split('/');
			assetsManifest['fourth-section'][splittedKey[0]].push(`${STORAGE_FOURTH_URL}/${content.Key._text}`);
		});
		return assetsManifest;
	} catch (err) {
		throw err;
	}
};
