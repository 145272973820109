import { Dialog, Grow, Slide } from '@mui/material';
import React, { forwardRef, useState } from 'react';
import Slider from 'react-slick';
import Section from '../Section';
import './index.scss';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImageIcon from '@mui/icons-material/Image';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAssetsManifestContext } from '../contexts/AssetsManifestContext';

const isVideo = asset => asset?.match(/\.(mov|MOV|mp4)$/);

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const FourthSecion = () => {
	const assetsManifest = useAssetsManifestContext();
	const outfit = assetsManifest['fourth-section'].outfit;
	const decoration = assetsManifest['fourth-section'].decoration;
	const baskets = assetsManifest['fourth-section'].baskets;
	const [category, setCategory] = useState();
	const [previewImg, setPreviewImg] = useState();
	const selecedAsset = category?.[previewImg];
	const moveLeft = () => {
		if (previewImg !== undefined && previewImg < category?.length - 1) {
			setPreviewImg(previewImg + 1);
		}
	};
	const moveRight = () => {
		if (previewImg) {
			setPreviewImg(previewImg - 1);
		}
	};
	return (
		<Section id='fourthSection' className='fourth-section'>
			<div className='width-bounding'>
				<h2 className='header'>גלריה</h2>
				{!category && (
					<Slide in direction='up'>
						<div>
							<Slider
								className='category-images-container'
								dots={false}
								slidesToShow={3}
								lazyLoad
								arrows
								responsive={[{ breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}>
								<div className='category-image' onClick={() => setCategory(baskets)}>
									<div className='photo-container'>
										<VisibilityIcon />
										<img src={baskets.find(item => item.includes('main'))} alt='basket' />
									</div>
									<div className='title'>סלסלות</div>
								</div>
								<div className='category-image' onClick={() => setCategory(outfit)}>
									<div className='photo-container'>
										<VisibilityIcon />
										<img src={outfit.find(item => item.includes('main'))} alt='outfit' />
									</div>
									<div className='title'>תלבושות</div>
								</div>
								<div className='category-image' onClick={() => setCategory(decoration)}>
									<div className='photo-container'>
										<VisibilityIcon />
										<img src={decoration.find(item => item.includes('main'))} alt='decoration' />
									</div>
									<div className='title'>תפאורה</div>
								</div>
							</Slider>
						</div>
					</Slide>
				)}
				{category && (
					<>
						<span className='back-button' onClick={() => setCategory()}>
							<ArrowForwardIosIcon />
							חזור
						</span>
						<div className='images-container'>
							{category.map((asset, index) => {
								return (
									<Grow in key={index} timeout={index * 100}>
										<div className='photo-container'>
											{!isVideo(asset) && (
												<>
													<ImageIcon className='small-icon' />
													<img src={asset} onClick={() => setPreviewImg(index)} />
												</>
											)}
											{isVideo(asset) && (
												<>
													<OndemandVideoIcon className='small-icon' />
													<video
														src={asset}
														controls={false}
														onClick={() => setPreviewImg(index)}
														autoPlay
														playsInline
														muted
														preload='metadata'
													/>
												</>
											)}
										</div>
									</Grow>
								);
							})}
						</div>
					</>
				)}
				<Dialog
					className='assets-dialog'
					open={Boolean(selecedAsset)}
					TransitionComponent={Transition}
					onKeyDown={event => {
						if (event.code === 'ArrowLeft') {
							moveLeft();
						} else if (event.code === 'ArrowRight') {
							moveRight();
						}
					}}
					keepMounted
					onClose={() => setPreviewImg()}>
					<>
						<ArrowBackIosNewIcon onClick={moveLeft} className='arrow-icon left' />
						{!isVideo(selecedAsset) && <img src={selecedAsset} />}
						{isVideo(selecedAsset) && <video src={selecedAsset} autoPlay playsInline preload='metadata' />}
						<ArrowForwardIosIcon onClick={moveRight} className='arrow-icon right' />
					</>
				</Dialog>
			</div>
		</Section>
	);
};

export default FourthSecion;
