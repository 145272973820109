import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './style/default.scss';
import Home from './pages/Home';
import ThemeProvider from './components/ThemeProvider';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import '../node_modules/animate.css/animate.min.css';
import AssetsManifestProvider from './components/contexts/AssetsManifestContext';

const App = () => {
	return (
		<>
			<AssetsManifestProvider>
				<ThemeProvider>
					<BrowserRouter>
						<Routes>
							<Route exact path='/' element={<Home />} />
						</Routes>
					</BrowserRouter>
				</ThemeProvider>
			</AssetsManifestProvider>
		</>
	);
};

export default App;
