import React from 'react';
import FourthSecion from '../../components/FourthSection';
import MainHeader from '../../components/MainHeader';
import SecondSection from '../../components/SecondSection';
import ThirdSecion from '../../components/ThirdSecion';
import VideoSection from '../../components/VideoSection';
import SubLogo from '../../assets/sub-logo.png';
import './index.scss';
import FifthSection from '../../components/FifthSection';

const Home = () => {
	return (
		<div>
			<MainHeader />
			<div className='sections'>
				<div className='sub-logo'>
					<div className='sub-logo-container'>
						<div className='basad'>
							<h4 className='center'>בעזרת השם נעשה ונצליח</h4>
						</div>
						<img src={SubLogo} alt='sublogo' />
					</div>
				</div>
				<VideoSection />
				<SecondSection />
				<ThirdSecion />
				<FourthSecion />
				<FifthSection />
			</div>
		</div>
	);
};

export default Home;
