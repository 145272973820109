import React from 'react';
import Section from '../Section';
import './index.scss';
import instagram from '../../assets/icons/instagram.svg';
import facebook from '../../assets/icons/facebook.svg';
import whatsapp from '../../assets/icons/whatsapp.svg';
import phone from '../../assets/icons/phone.svg';
// import location from '../../assets/icons/location.svg';

const FifthSection = () => {
	return (
		<Section id='fifthSection' className='fifth-section'>
			<div className='width-bounding'>
				<h2 className='header'>צור קשר</h2>
				<h3 className='sub-header'>נשמח להפיק גם לכם את האירוע שחלמתם עליו!</h3>
				<div className='icons-text'>
					<a href='https://www.instagram.com/bnot.melech/' target='_blank' rel='nofollow'>
						<div className='icon-text-container'>
							<img src={instagram} alt='instagram' />
							<div className='divider'></div>
							<div className='text'>Bnot.melech</div>
						</div>
					</a>
					<a href='https://wa.me/+972543000747' target='_blank' rel='nofollow'>
						<div className='icon-text-container'>
							<img src={whatsapp} alt='whatsapp' />
							<div className='divider'></div>
							<div className='text'>
								{`054-3000747
							דוריס גז`}
							</div>
						</div>
					</a>
					<a href='https://www.facebook.com/profile.php?id=100057374713390' target='_blank' rel='nofollow'>
						<div className='icon-text-container'>
							<img src={facebook} alt='facebook' />
							<div className='divider'></div>
							<div className='text'>בנות מלך דוריס גז</div>
						</div>
					</a>
					<a href='tel:0543000747' rel='nofollow'>
						<div className='icon-text-container'>
							<img src={phone} alt='phone' />
							<div className='divider'></div>
							<div className='text'>
								{`054-3000747
							דוריס גז`}
							</div>
						</div>
					</a>
					{/* <a href='https://goo.gl/maps/2G573VRqU5RNg8KL6' target='_blank' rel="nofollow">
						<div className='icon-text-container'>
							<img src={location} />
							<div className='divider'></div>
							<div className='text'>אריאל, ישראל</div>
						</div>
					</a> */}
				</div>
			</div>
		</Section>
	);
};

export default FifthSection;
