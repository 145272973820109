import React from 'react';
import Section from '../Section';
import './index.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { STORAGE_FOURTH_URL } from '../../scripts/gcpStorage.mjs';

const SecondSection = () => {
	return (
		<Section id='secondSection' className='simple-section second-section'>
			<div className='width-bounding'>
				<AnimationOnScroll animateIn='animate__fadeInUp' animateOnce animatePreScroll={false}>
					<div className='right-side'>
						<h2 className='header'>תפאורה</h2>
						<div className='text'>
							הגעתם לרגע המיוחד שלכם, אז קודם כל מזל טוב! גם אתם חלמתם על טקס חינה שלא ישכחו אף פעם? הגעתם
							למקום הנכון. אחד הדברים ששומרים על טקס חינה אותנטי ומרוקאי, זה התפאורה המדהימה שמושכת את כל
							העיניים של האורחים. אנחנו בבנות מלך מבינים את חשיבות התפאורה לחינה ולכן אנחנו משקיעים את
							מירב המאמצים, המחשבה והיצירתיות שלנו כדי להקים את התפאורה הכי מפוארת שאפשר. אנחנו מציעים
							מגוון תפאורות שבו כל אחד יוכל לבחור לפי טעמו האישי. כל תפאורה מביאה יופי יחודי משלה. יש לנו
							מבחר רחב של קסם שיהפוך לכם את הערב למדהים, כורסאות חתן כלה, תלמון, קשתות, כדים, אפיריונים
							הנושאים את הזוג המלכותי לטקס, שולחן שוק מרהיב ואותנטי, שטיחים מיוחדים וריהוט אותנטי וזה רק
							חלק קטן ממה שמחכה לכם בתפאורה של בנות מלך.
						</div>
					</div>
				</AnimationOnScroll>
				<AnimationOnScroll animateIn='animate__fadeInUp' animateOnce animatePreScroll={false}>
					<div className='photo-container left-side'>
						<img
							src={`${STORAGE_FOURTH_URL}/outfit/cd61bd75-ae08-43d3-9816-d98c4617024a.JPG`}
							alt='outfit'
						/>
					</div>
				</AnimationOnScroll>
			</div>
		</Section>
	);
};

export default SecondSection;
