import { SwipeableDrawer } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Logo224 from '../../assets/logo224.png';
import './index.scss';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile, isTablet } from '../../utils/helpers';

const scrollIntoElement = id => {
	const element = document.getElementById(id);
	// smooth scroll to element and align it at the bottom
	if (element) window.scrollTo({ top: element.offsetTop - 80, behavior: 'smooth' });
};

const navigationItems = generalOnClick => [
	<div
		key={'secondSection'}
		onClick={() => {
			if (generalOnClick) generalOnClick();
			scrollIntoElement('secondSection');
		}}>
		תפאורה
	</div>,
	<div
		key={'thirdSection'}
		onClick={() => {
			if (generalOnClick) generalOnClick();
			scrollIntoElement('thirdSection');
		}}>
		תלבושות
	</div>,
	<div
		key={'fourthSection'}
		onClick={() => {
			if (generalOnClick) generalOnClick();
			scrollIntoElement('fourthSection');
		}}>
		גלריה
	</div>,
	<div
		key={'fifthSection'}
		onClick={() => {
			if (generalOnClick) generalOnClick();
			scrollIntoElement('fifthSection');
		}}>
		צור קשר
	</div>,
];
const MainHeader = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [responsiveMode, setResponsiveMode] = useState('desktop');
	const fixedHeaderRef = useRef();
	const toggleDrawer = open => {
		setMenuOpen(open);
	};
	const handleWindowResize = () => {
		if (isMobile()) setResponsiveMode('mobile');
		else if (isTablet()) setResponsiveMode('tablet');
		else setResponsiveMode('desktop');
	};
	const handleScrolling = () => {
		if (!fixedHeaderRef?.current) return;
		const DESKTOP_HEADER_HEIGHT = 275;
		const MOBILE_HEADERHEIGHT = 150;
		const HEADER_FIXED_HEIGHT = 60;
		if (window.scrollY >= (isMobile() ? MOBILE_HEADERHEIGHT : DESKTOP_HEADER_HEIGHT) - HEADER_FIXED_HEIGHT) {
			fixedHeaderRef.current.classList.add('active');
		} else fixedHeaderRef.current.classList.remove('active');
	};
	useEffect(() => {
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);
		window.addEventListener('scroll', handleScrolling);
		return () => {
			window.removeEventListener('scroll', handleScrolling);
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);
	const Logo = (
		<img
			src={Logo224}
			className='logo'
			alt='logo'
			onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
		/>
	);
	return (
		<>
			<div className='main-header-container'>
				<div className='top-right'>
					<span>בס"ד</span>
					{responsiveMode === 'mobile' && (
						<MenuIcon className='menu-icon' style={{ color: 'white' }} onClick={() => toggleDrawer(true)} />
					)}
				</div>
				{Logo}
				<div className='main-header-fixed' ref={fixedHeaderRef}>
					{responsiveMode === 'mobile' && (
						<MenuIcon className='menu-icon' style={{ color: 'white' }} onClick={() => toggleDrawer(true)} />
					)}
					{responsiveMode !== 'mobile' && <div className='navigation-items'>{navigationItems()}</div>}
					{Logo}
				</div>
			</div>
			{responsiveMode === 'mobile' && (
				<SwipeableDrawer
					anchor='top'
					open={menuOpen}
					onClose={() => toggleDrawer(false)}
					onOpen={() => toggleDrawer(true)}>
					<div className='swipeable-drawer'>
						<CloseIcon onClick={() => toggleDrawer(false)} />
						<div className='items'>{navigationItems(() => toggleDrawer(false))}</div>
					</div>
				</SwipeableDrawer>
			)}
		</>
	);
};

export default MainHeader;
